<template>
  <div class="development">
    <div class="content">
      <div class="pc">
        <a-timeline mode="alternate">
          <a-timeline-item color="red" v-for="item of list" :key="item.id">
            <div slot="dot">{{ item.dateTime }}</div>
            <div class="item" v-html="item.content"></div>
          </a-timeline-item>
        </a-timeline>
      </div>
    </div>
  </div>
</template>

<script>
import { queryDevelopmentPathList } from "@/api/api.js";
export default {
  name: "development",
  data() {
    return {
      list: [],
    };
  },
  async created() {
    await queryDevelopmentPathList().then((res) => {
      this.list = res.data.rows.reverse();
    });
    this.move();
  },
  methods: {
    move() {
      const element = document.querySelectorAll(".item");
      let time = 1;
      element.forEach((item, index) => {
        item.classList.add("animate__animated", "animate__fadeInDownBig");
        item.style.setProperty("--animate-duration", `${time}s`);
        time += 0.5;
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and(min-width:1060px) {
  .development {
    .pc {
      width: 68%;
    }
  }
}
@media screen and(max-width:1060px) {
  .development {
    .pc {
      width: 80%;
    }
  }
}
@media screen and(max-width:657px) {
  .development {
    .pc {
      width: 90%;
    }
  }
}
.development {
  width: 100%;
  .content {
    padding: 50px 0;
    margin: auto;
    .pc {
      // width: 68%;
      margin: auto;
      /deep/ .ant-timeline {
        .ant-timeline-item-head {
          > div {
            width: 120px;
            height: 30px;
            line-height: 30px;
            background: #d20505;
            border-radius: 14px;
            text-align: center;
            color: #fff;
          }
        }
        .ant-timeline-item-content {
          > div {
            margin-bottom: 20px;
            background: rgba(210, 5, 5, 0.05);
            padding: 10px 30px 10px 10px;
            border-radius: 4px;
            > :first-child {
              font-size: 14px;
              font-weight: bold;
              color: #333333;
            }
            > :last-child {
              color: #999999;
            }
          }
        }
        .ant-timeline-item-left {
          .ant-timeline-item-content {
            .item {
              margin-left: 60px;
            }
          }
        }
        .ant-timeline-item-right {
          .ant-timeline-item-content {
            .item {
              margin-right: 60px;
            }
          }
        }
      }
    }
  }
}
</style>
